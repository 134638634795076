import { UserConStant, userRequiredFieldList } from "presentation/constant/User/UserConstant";
import { useUserVM } from "presentation/hook/User/useUserVM";
import { useUserTracked } from "presentation/store/User/UserProvider";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";

const UserForm: React.FC = () => {
    const [userState] = useUserTracked();
    const userVM = useUserVM();
    const currentUser = userState.userState.currentSelectedData;
    const { isAdd, isRead, isSaveClicked } = userState.userState;
    const USER_CONSTANT = UserConStant.user;
    const allFormState = userState.userState.allFormState;

    return (<>
        <div className={'im-flex-row'} style={{ marginBottom: '10px' }}>
            <div className={'flex-row-item'} style={{ width: "300px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={!isAdd}
                    readOnlyValue={currentUser.name ?? ''}
                    fieldValue={currentUser.name}
                    fieldLabel={USER_CONSTANT.USERNAME}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'name'}
                    maxLength={30}
                    requiredFieldList={userRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        userVM.onUserFieldChange(fieldKey, fieldValue && fieldValue.toString().toLocaleLowerCase(), fFullValue)} />
            </div>
        </div>
        <div className={'im-flex-row'} style={{ marginBottom: '10px' }}>
            <div className={'flex-row-item'} style={{ width: "300px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentUser.firstName ?? ''}
                    fieldValue={currentUser.firstName}
                    fieldLabel={USER_CONSTANT.USERFIRSTNAME}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'firstName'}
                    maxLength={150}
                    requiredFieldList={userRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        userVM.onUserFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            <div className={'flex-row-item'} style={{ width: "300px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentUser.lastName ?? ''}
                    fieldValue={currentUser.lastName}
                    fieldLabel={USER_CONSTANT.USERLASTNAME}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'lastName'}
                    maxLength={150}
                    requiredFieldList={userRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        userVM.onUserFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
        </div>
        <div className={'im-flex-row'} style={{ marginBottom: '10px' }}>
            <div className={'flex-row-item'} style={{ width: "300px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={false}
                    readOnlyValue={currentUser.defaultEntryPoint ?? ''}
                    fieldValue={currentUser.defaultEntryPoint ?? ''}
                    fieldLabel={USER_CONSTANT.PREFERENCEENTRYPOINT}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.DROPDOWN}
                    fieldKey={'defaultEntryPoint'}
                    options={userState.dynamicOptions.menuDropdownOptions ?? []}
                    requiredFieldList={userRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        userVM.onUserFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
            <div className={'flex-row-item'} style={{ width: "300px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    readOnlyValue={currentUser.emailAddress ?? ''}
                    fieldValue={currentUser.emailAddress}
                    fieldLabel={USER_CONSTANT.EMAIL}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'emailAddress'}
                    maxLength={200}
                    requiredFieldList={userRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        userVM.onUserFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
        </div>
        <div className={'im-flex-row width-500'} style={{ marginBottom: '10px' }}>
            <div className={'flex-row-item'} style={{ width: "630px" }}>
                <NbisCommonField
                    errorMessages={allFormState}
                    isReadOnly={isRead}
                    isShowOptional={true}
                    readOnlyValue={currentUser.description ?? ''}
                    fieldValue={currentUser.description}
                    fieldLabel={USER_CONSTANT.DESCRIPTION}
                    isSaveClicked={isSaveClicked}
                    fieldType={FieldType.TEXT}
                    fieldKey={'description'}
                    maxLength={60}
                    requiredFieldList={userRequiredFieldList}
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                        userVM.onUserFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
        </div>
        <div className={'im-flex-row'} style={{ marginBottom: '10px' }}>
            <div className={'flex-row-item'} style={{width:"300px"}}>
                <DateRangePickerComponent
                    disabled={isRead}
                    label={USER_CONSTANT.VALIDITYDATERANGE}
                    width="300px"
                    fieldNames={{ startField: "validityFromDate", endField: "validityToDate" }}
                    dateRange={{ startDate: currentUser.validityFromDate, endDate: currentUser.validityToDate }}
                    onDatesChange={userVM.onDateRangeChange}
                    errorMessage={allFormState ? (allFormState["validityFromDate"] ?? allFormState["validityToDate"]) : ""}
                />
            </div>
            <div className={'flex-row-item'} style={{width:"300px"}}>
                <NbisCommonField
                    fieldKey={"systemAccessMode"}
                    requiredFieldList={[]}
                    fieldLabel={USER_CONSTANT.ENABLED}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={(currentUser.systemAccessMode === 'DLT' || currentUser.systemAccessMode === 'DLP') ? false : true}
                    disabled={isRead}
                    onChange={(e) => userVM.onEnabledChange(e.checked, "systemAccessMode")}
                    />
            </div>
        </div>
    </>);
}

export default UserForm;