import { CustomCellRendererProps } from "ag-grid-react";
import { UserGroupConstant } from "presentation/constant/UserGroup/UserGroupConstant";
import { defineColumn } from "presentation/view/components/TableWrapper/BasicTableConstants";

const USERGROUP_CONSTANT = UserGroupConstant.userGroup;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_USER_GROUP_COL_DEF: any[] = [
    {
        headerName: USERGROUP_CONSTANT.USERGROUPNAME,
        field: 'name',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: true,
        width: 150,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: USERGROUP_CONSTANT.USERGROUPDESC,
        field: 'description',
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        filter: true,
        width: 180,
    },
]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});